import React from 'react'
import {
    Card, 
} from 'antd'

import RApaxChart from "react-apexcharts";

const { Meta } = Card

const GraphApex = ({param}) => {

    const dayInWeek = ['Oth', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    const datasource = param.datasource
    console.log('datasource week', datasource)
    const chartRes = param.chart  
    
    const opt   = { 
                    title: chartRes.caption,
                    subtitle: param.subTitle,
                    width: param.width,
                    height: (param.height ? param.height : '300px'),
                }

    const setMax  = (value) =>{
        var val = value
        const valArr = val.toString().split('')

        var _valArr = []

        valArr.forEach(function(val,index,array){
            array[index]    = parseInt(val, 10)
            _valArr[index]  = 0
        })

        if(value < 9){
            val = 20 
        }else if(value < 70){
            val = 80               
        }else if(value < 99){
            val = 120
        }else if(value < 500){
            val = 600           
        }else if(value < 999){
            val = 1000 + (valArr[1] > 8 ? 200 : 0) 
        }else{          
            _valArr[1] = parseInt(valArr[1],10) + (_valArr[2] >= 6 ? 1 : 0)               
            _valArr[0] = parseInt(valArr[0],10) + (_valArr[1] >= 8 ? 1 : 0) 

            _valArr[1] = _valArr[1] > 9 ? 0 : _valArr[1]  

            val = parseInt(_valArr.join(''), 10)
        }

        return(val)    
    }
            

    var val = {"row":[], "label":[], "value":[], 
                    "chart":{
                        "bar":{
                            "bgcolor":[]
                        }, 
                    },
                    "max":0,  
                }
                const weekVal = JSON.parse(JSON.stringify(val));  

    var dataRow = 0   
    if(datasource && datasource.data){ 
         
        for(var x=0; x<dayInWeek.length; x++){
            weekVal.value[x] = 0
            weekVal.label[x] = dayInWeek[x] ? dayInWeek[x].toUpperCase() : "-"
            weekVal.row[x] = x  
            weekVal.chart.bar.bgcolor[x] = '#efefef'    
        }     
   
        
        for(const [keyi, valuei] of Object.entries(datasource.data)){
            dataRow = (dataRow + 1)

            if(weekVal.max < valuei.count){
                weekVal.max = valuei.count    
            } 

            val.row[keyi] = keyi
            weekVal.value[(valuei.day>0 ? valuei.day : 0)] = valuei.count
        }

        val = weekVal

        if(val.value[0] === 0 ){ 
            val.label.splice(0, 1)
            val.value.splice(0, 1)
        }
        
    }

    const valueList = val

    const apexChartConfig = {
                                    options: {
                                        chart: {
                                            id: "bar",
                                            toolbar: {
                                                show: false,
                                            },                       
                                        },
                                        dataLabels: {
                                            enabled: false,
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: false,
                                                startingShape: 'rounded',
                                                endingShape: 'rounded',
                                                columnWidth: '10%',
                                                barHeight: '30%',
                                                distributed: false,
                                                rangeBarOverlap: true,
                                                rangeBarGroupRows: false,
                                                colors: {
                                                    ranges: [{
                                                        from: 0,
                                                        to: 0,
                                                        color: undefined
                                                    }],
                                                    backgroundBarColors: val.chart.bar.bgcolor,
                                                    backgroundBarOpacity: 1,
                                                    backgroundBarRadius: 5,
                                                },
                                            }
                                        },
                                        xaxis: {
                                            categories: valueList.label, 
                                            axisBorder: {
                                                show: false,
                                                color: '#78909C',
                                                offsetX: 0,
                                                offsetY: 0
                                            },
                                            lines: {
                                                show: false
                                            },      
                                            axisTicks: {
                                                show: false
                                            },         
                                            labels:{
                                                style:{
                                                    colors: '#B1B1B1'
                                                }   
                                            }                                                               
                                        },
                                        yaxis: {
                                            min: 0,
                                            max: setMax( val.max ),   
                                            labels:{
                                                style:{
                                                    colors: '#B1B1B1'
                                                }   
                                            }                                                               
                                        },
                                        legend:{
                                            show: false
                                        },
                                        grid: {
                                            yaxis: {
                                                lines: {
                                                show: false
                                                }
                                            }
                                        }
                                                    
                                        
                                
                                    },
                                    series: [
                                        {
                                        name: "Number",
                                        data: valueList.value, 
                                        color: '#FF9606'
                                        }
                                    ]
                                }
    
    //console.log("week-data", datasource)
    //console.log("week-graph", valueList)

    return (<Card
                hoverable
                cover={<div className='fgma-chart' style={{ marginTop: '20px', fontSize: '1.3em', paddingLeft: '25px'}}>
                        <b class='chartHeader' > {chartRes.caption} </b>
                        </div>}
            >
                <Meta
                    description={<div className="" style={{ verticalAlign: 'top', height: opt.height }}>
                    <RApaxChart
                        options={apexChartConfig.options}
                        series={apexChartConfig.series}
                        type= "area"
                        width= "100%"
                        height="100%"
                    />                     
                    </div>}
                />
            </Card>
    )


}

export default GraphApex
